@tailwind base;
@tailwind components;
@tailwind utilities;

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.nav-item {
  @apply hover:text-white transition pb-3 lg:pb-0;
}

.intro-paragraph {
  @apply text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl;
}

/* MailChimp */
#mc_embed_signup {
  background: #fff;
  clear: left;
  font: 14px Helvetica, Arial, sans-serif;
  width: 600px;
}
